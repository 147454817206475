import * as Components from '@/molecules'

const isDevelopment = process.env.NODE_ENV === 'development'

export const adaptTemporalyUndefinedComponents = (inputType) => {
  const CMS_COMPONENTS = []
  const FE_COMPONENTS = []
  if (CMS_COMPONENTS.includes(inputType)) {
    return FE_COMPONENTS[CMS_COMPONENTS.indexOf(inputType)]
    // return `${FE_COMPONENTS[CMS_COMPONENTS.indexOf(inputType)]} - 🛠️ CMS`
  }
  return inputType
}

const SectionBuilder = ({ data }) => {
  const fallbackList = []
  return (
    <>
      {data
        ? data.map((props, index) => {
            const componentType = adaptTemporalyUndefinedComponents(
              props.acf_fc_layout,
            )
            const isHeroComponent =
              typeof componentType === 'string' &&
              componentType.toLowerCase().includes('hero') &&
              index === 0

            const itemKey = `component-${componentType}-${index}`
            const Component = Components[componentType]
            const fallBack = isDevelopment ? componentType : ''
            const _props = {
              ...props,
              isHero: isHeroComponent,
            }

            if (Component) {
              return (
                <Component
                  {..._props}
                  key={itemKey}
                />
              )
            } else {
              fallbackList.push(fallBack)
            }
          })
        : null}
      {fallbackList.length > 0 && <Fallback fallback={fallbackList} />}
    </>
  )
}

const Fallback = ({ fallback }) => {
  const fallbackStyle = {
    position: 'fixed',
    bottom: '0',
    paddingBlock: '.5rem',
    paddingInline: '.5rem',
    backgroundColor: 'orange',
    border: '.25rem solid darkorange',
    borderRadius: '0.5rem',
    margin: '.5rem',
    zIndex: '9999',
  }

  if (!isDevelopment) return <></>

  return (
    <div style={fallbackStyle}>
      <p>{'Section Builder undefined <Components/> :'}</p>
      <ul>
        {fallback.map((item, index) => (
          <li key={index}>{item} </li>
        ))}
      </ul>
    </div>
  )
}
export default SectionBuilder
